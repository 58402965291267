// wrapper around axios for ajax functionality
// sets globals when fetching content to help with Rspec wait_for_ajax
// adapted from: https://gist.github.com/sheharyarn/7f43ef98c5363a34652e60259370d2cb

import urls from './urls'
import axios from 'axios'
export { showError } from './errors'

export const request = options => {
  const onSuccess = response => {
    popAjax()
    window.current_ajax_request = null // for rspec wait_for_ajax
    return response.data
  }

  const onError = error => {
    popAjax()
    console.error('Request Failed:', error.config)

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status)
      console.error('Data:', error.response.data)
      console.error('Headers:', error.response.headers)

      if (error.response.status === 401) {
        window.location = urls.login
        return
      }
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message)
    }

    // if a response comes back, then error.response **will** be populated
    // see: https://github.com/axios/axios#handling-errors
    return Promise.reject(error.response || error)
  }

  return axios
    .create()(options)
    .then(onSuccess)
    .catch(onError)
}

// eg get('/api/foo', { params: { foo: 'bar'} })
export const get = (url, options) => {
  let params = Object.assign({ url, method: 'GET' }, options)
  pushAjax(params)
  return request(params)
}

export const post = (url, data) => {
  const params = { method: 'POST', url, data }
  pushAjax(params)
  return request(params)
}

export const put = (url, data) => {
  const params = { method: 'PUT', url, data }
  pushAjax(params)
  return request(params)
}

export const destroy = (url, data) => {
  const params = { method: 'DELETE', url, data }
  pushAjax(params)
  return request(params)
}

// used for tests wait_for_ajax
function popAjax() {
  window.activeAjax -= 1
}

function pushAjax(params) {
  if (window.activeAjax === undefined) {
    window.activeAjax = 0
  }

  window.activeAjax += 1
  window.currentAjaxRequest = params // for rspec wait_for_ajax
}
