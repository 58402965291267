import React from 'react';
import PropTypes from 'prop-types';
import { post, destroy } from '../../../../../utils/ajax';
import Toggle from 'react-toggle';
import { alert } from '../../../../../utils/alert';

export default class IndicatorTableItemForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static propTypes = {
    itemId: PropTypes.number.isRequired,
    indicatorId: PropTypes.number.isRequired,
    reportId: PropTypes.number.isRequired,
    indicatorTableItemId: PropTypes.number,
  };

  state = {
    itemEnabled: !!this.props.indicatorTableItemId,
  };

  handleSubmit = (event) => {
    const { itemEnabled } = this.state;
    event.preventDefault();

    if (itemEnabled === false) {
      this.handleCreate();
    } else {
      this.handleDestroy();
    }
  };

  handleCreate = (_) => {
    const { itemId, indicatorId, reportId } = this.props;
    const { itemEnabled } = this.state;

    post('/api/web/indicator_table_items', {
      report_id: reportId,
      indicator_id: indicatorId,
      indicator_table_item: {
        item_id: itemId,
      },
      authenticity_token: document.querySelector('meta[name=csrf-token]')
        .content,
    }).catch(() => {
      alert('There was an error adding the item', 'danger');
    }).then(() => {
      this.setState({ itemEnabled: !itemEnabled });
    });
  };

  handleDestroy = (_) => {
    const { indicatorTableItemId } = this.props;

    destroy(`/api/web/indicator_table_items/${indicatorTableItemId}`, {
      authenticity_token: document.querySelector('meta[name=csrf-token]')
        .content,
    }).catch(() => {
      alert('There was an error deleting the item from this report.', 'danger');
    }).then(() => {
      this.setState({ itemEnabled: false });
    });
  };

  render() {
    const { itemEnabled } = this.state;

    return (
      <div>
        <form>
          <div className="form-check form-switch">
            <input
              style={{ cursor: 'pointer' }}
              className="form-check-input"
              checked={itemEnabled}
              onChange={this.handleSubmit}
              type="checkbox"
              role="switch"
            />
          </div>
        </form>
      </div>
    );
  }
}
