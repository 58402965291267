import {titleize} from "../../../../utils"

export function formatAudience(group) {
  if (group == 'parent_guardian') {
    return "Parent/Guardian"
  } else if (group == 'assistant_principal') {
    return 'Assistant Principal'
  } else {
    return titleize(group)
  }
}
