import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ItemProgressBar from '../item/progress_bar.jsx';
import Spinner from '../indicator/absolute.jsx';

export default class ConstructInfoModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    reportId: PropTypes.number,
    reportConstruct: PropTypes.object,
    reportConstructId: PropTypes.number,
  };

  render() {
    const { onHide, show, reportConstruct, items, loading, reportId } = this.props;

    return (
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {!!reportConstruct.construct && reportConstruct.construct.name}{' '}
            Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 mt-1">
              {loading ? (
                <div style={{ height: 150 }}>
                  <Spinner loading={loading} />
                </div>
              ) : (
                <ItemProgressBar
                  reportId={reportId}
                  items={items}
                  withForm={false}
                />
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
