import React from 'react'
import PropTypes from 'prop-types'
import {Button} from 'react-bootstrap'
import ItemModal from './modal.jsx'
import {get} from '../../../../../utils/ajax'
import {alert} from '../../../../../utils/alert'

class ItemButton extends React.Component {
  static propTypes = {
    updateCapacity: PropTypes.func.isRequired,
    reportConstructId: PropTypes.number.isRequired,
    reportId: PropTypes.number.isRequired
  }

  state = {
    modalOpen: false
  }

  handleModalHide = () => {
    this.setState({modalOpen: false})
  }

  openModal = () => {
    this.setState({modalOpen: true})
    get('/api/web/report_items', {
      params: {
        report_construct_id: this.props.reportConstructId
      }
    }).then(res => {
      this.setState({
        items: res.map(data => ({value: data.id, label: data.item.question, isDisabled: data.display}))
      })
    }).catch(() => {
      alert('There was an error retrieving the items.', 'danger')
    })
  }

  render() {
    const {items, modalOpen} = this.state
    const {reportIndicatorIds, updateCapacity, reportConstructId} = this.props
    return (<div>
      <ItemModal show={modalOpen} onHide={this.handleModalHide} items={items} reportConstructId={reportConstructId} updateCapacity={updateCapacity}/>

      <Button onClick={this.openModal} className="btn-light btn-sm">
        <i className='fa fa-plus text-primary'></i>
        &nbsp; Add Item
      </Button>
    </div>)
  }
}

export default ItemButton
