import React from 'react';
import PropTypes from 'prop-types';
import ItemChart from './chart.jsx';
import { get, put } from '../../../../../utils/ajax';
import { alert } from '../../../../../utils/alert';
import { Button } from 'react-bootstrap';

class Item extends React.Component {
  static propTypes = {
    itemId: PropTypes.number.isRequired,
    reportId: PropTypes.number.isRequired,
    reportItemId: PropTypes.number.isRequired,
    updateCapacity: PropTypes.func.isRequired,
    itemColor: PropTypes.string,
    display: PropTypes.string,
    edittable: PropTypes.bool.isRequired,
  };

  state = {
    percent: 0,
  };

  fetchItemScore = () => {
    get(`/api/web/reports/${this.props.reportId}/item_score`, {
      params: {
        report_id: this.props.reportId,
        item_id: this.props.itemId,
      },
    })
      .then((res) => {
        this.setState({ percent: res });
      })
      .catch(() => {
        alert('There was an error retrieving the item data', 'danger');
      });
  };

  handleUpdate = (_) => {
    const { updateCapacity, reportItemId } = this.props;
    put(`/api/web/report_items/${reportItemId}`, {
      report_item: {
        display: false,
      },
      authenticity_token: document.querySelector('meta[name=csrf-token]')
        .content,
    })
      .then((res) => {
        updateCapacity();
        alert('Item removed from display', 'success');
      })
      .catch(() => {
        alert(
          'There was an error deleting the item from this report.',
          'danger'
        );
      });
  };

  componentDidMount() {
    this.fetchItemScore();
  }

  render() {
    const { display, reportItemId, itemColor, edittable } = this.props;
    const { percent } = this.state;

    return (
      <div>
        <ItemChart percent={percent} color={itemColor} />
        <p>{display}</p>
        
        {edittable && (
          <Button
            className="btn btn-sm btn-light"
            onClick={this.handleUpdate}
          >
            <i className="fa fa-trash text-danger"></i> Delete Item
          </Button>
        )}
      </div>
    );
  }
}

export default Item;
