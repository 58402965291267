import React from 'react'
import PropTypes from 'prop-types'
import ReportCapacityModal from './modal.jsx'
import { get } from '../../../../../utils/ajax'
import { alert } from '../../../../../utils/alert'

class AddCapacityLink extends React.Component {
  static propTypes = {
    reportId: PropTypes.number.isRequired,
    fetchReport: PropTypes.func.isRequired
  }

  state = {
    modalOpen: false,
    reportCapacities: []
  }

  componentDidMount() {
    get(`/api/web/reports/${this.props.reportId}/report_capacities`)
    .then(res => {
      this.setState({ reportCapacities: res.map(data => data.capacity_id) })
    })
    .catch(() => {
      alert('There was an error retrieving the report capacities.', 'danger')
    })
  }

  handleModalHide = () => {
    this.setState({ modalOpen: false })
  }

  inReport = (id) => {
    return this.state.reportCapacities.includes(id)
  }

  createOptions = (res) => {
    return res.map(data => ({value: data.id, label: data.name, isDisabled: this.inReport(data.id)}))
  }

  openModal = () => {
    this.setState({ modalOpen: true })
    get('/api/web/capacities')
    .then(res => {
      this.setState({ capacities: this.createOptions(res) })
    })
    .catch(() => {
      alert('There was an error retrieving the capacities.', 'danger')
    })
  }

  render() {
    const { modalOpen, capacities } = this.state
    const { reportId, fetchReport } = this.props

    return(
      <div className="col-md-10 col-md-offset-1 mt-1">
      <h2>Add Capacity</h2>
      <a onClick={this.openModal} className="btn btn-primary btn-small">+ Add Capacities</a>

        <ReportCapacityModal
          show={modalOpen}
          onHide={this.handleModalHide}
          capacities={capacities}
          reportId={reportId}
          fetchReport={fetchReport}
          />
      </div>
    )
  }
}

export default AddCapacityLink
