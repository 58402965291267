import React from 'react';
import PropTypes from 'prop-types';

const SectionAction = ({ sectionId, destroy }) => {
  const handleDestroy = (_) => {
    destroy(sectionId);
  };

  return (
    <div>
      <a className="btn btn-sm btn-light border-light me-1" onClick={handleDestroy}>
        <i className="fa fa-trash text-danger" aria-hidden="true" /> Delete
      </a>
    </div>
  );
};

SectionAction.propTypes = {
  sectionId: PropTypes.number.isRequired,
  destroy: PropTypes.func.isRequired,
};

export default SectionAction;
