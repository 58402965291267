import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { get } from "../../../../../utils/ajax";
import { alert } from '../../../../../utils/alert';
import ItemPickerModal from "./item-picker-modal.jsx";

export default class ItemPicker extends React.Component {
  static propTypes = {
    reportId: PropTypes.number.isRequired,
    reportConstructId: PropTypes.number.isRequired,
    fetchData: PropTypes.func.isRequired,
    itemIds: PropTypes.array.isRequired
  };

  state = {
    modalOpen: false,
    indicators: [],
    loading: false
  };

  handleModalHide = () => {
    this.setState({ modalOpen: false });
    this.props.fetchData();
  };

  openModal = () => {
    this.setState({ modalOpen: true, loading: true });
    get(`/api/web/report_indicators`, {
      params: {
        report_id: this.props.reportId
      }
    })
      .catch(() => {
        this.setState({ loading: false });
        alert("There was an error retrieving the indicator table", "danger");
      })
      .then(res => {
        this.setState({
          indicators: res.map(data => ({ value: data.id, label: data.name })),
          loading: false
        });
      });
  };

  render() {
    const { modalOpen, indicators, loading } = this.state;
    const { reportId, reportConstructId, fetchData, itemIds } = this.props;
    return (
      <>
        <ItemPickerModal
          show={modalOpen}
          onHide={this.handleModalHide}
          indicators={indicators}
          itemIds={itemIds}
          reportId={reportId}
          reportConstructId={reportConstructId}
          loading={loading}
          fetchData={fetchData}
        />

        <Button onClick={this.openModal} className="btn-light btn-sm">
          <i className="fa fa-plus text-danger" />
          &nbsp;Add Items
        </Button>
      </>
    );
  }
}
