import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { colorByIndex } from "../../../../../utils/color-class";
import { findDataPoint, createXCategories } from "./chart-helpers.jsx"

class BarChart extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired
  };

  render() {
    const { data } = this.props;
    const xCategories = createXCategories(data.map(d => d.yearly_chart.map(d => d[0])))

    const plotOptions = {
      chart: {
        type: "column",
        style: {
          fontFamily: "Avenir"
        }
      },
      title: {
        text: ""
      },
      credits: {
        enabled: false
      },
      xAxis: {
        categories: xCategories,
        crosshair: true
      },
      yAxis: {
        min: 1,
        max: 6,
        title: {
          text: "Capacity"
        },
        plotBands: [
          {
            label: "Low Capacity",
            from: 0,
            to: 4,
            color: "#F4DAD6"
          },
          {
            label: "Moderate Capacity",
            from: 4,
            to: 5,
            color: "#FDE8CF"
          },
          {
            label: "Strong Capacity",
            from: 5,
            to: 6,
            color: "#E7F2E4"
          }
        ]
      },
      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
          '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
          '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true
      },
      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0
        }
      },
      series: data.map((d, i) => ({
        name: d.construct.name,
        data: xCategories.map(year => findDataPoint(year, d.yearly_chart) ),
        color: colorByIndex(i)
      }))
    };

    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={plotOptions} />
      </div>
    );
  }
}

export default BarChart;
