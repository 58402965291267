import React from "react";
import PropTypes from "prop-types";
import SectionForm from "./section_form.jsx";
import { Modal } from "react-bootstrap";

export default class SectionModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    reportId: PropTypes.number.isRequired,
    reportSection: PropTypes.object
  };

  render() {
    const { onHide, show, reportSection, reportId, fetchData } = this.props;

    return (
      <Modal show={show} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Update Introduction Section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SectionForm
            reportId={reportId}
            reportSection={reportSection}
            onHide={onHide}
            fetchData={fetchData}
          />
        </Modal.Body>
      </Modal>
    );
  }
}
