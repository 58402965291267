import React from 'react';
import PropTypes from 'prop-types';
import IndicatorTable from './table.jsx';
import IndicatorButton from './button.jsx';
import Spinner from './absolute.jsx';
import { get } from '../../../../../utils/ajax';
import { alert } from '../../../../../utils/alert';
import PageHeader from '../../shared/PageHeader.jsx';
import { Card } from 'react-bootstrap';

export default class Indicator extends React.Component {
  static propTypes = {
    reportId: PropTypes.number.isRequired,
    edittable: PropTypes.bool.isRequired,
    year: PropTypes.number,
  };

  state = {
    data: [],
    loading: false,
  };

  fetchIndicatorTableData = () => {
    this.setState({ loading: true, data: [] });
    get(`/api/web/reports/${this.props.reportId}/indicator_table`, {
      params: {
        included: true,
      },
    })
      .catch(() => {
        this.setState({ loading: false });
        alert('There was an error retrieving the indicator table', 'danger');
      })
      .then((res) => {
        this.setState({ data: res, loading: false });
      });
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.fetchIndicatorTableData();
  }

  render() {
    const audiences = [
      'student',
      'parent_guardian',
      'principal',
      'faculty',
      'alumni',
      'therapist',
      'director',
      'admin_staff',
      'associates',
      'assistant_principal',
      'staff',
    ];
    const { data, loading } = this.state;
    const { edittable, reportId, year } = this.props;
    const indicatorIds = data.map((i) => i[0]);

    return (
      <div>
        <PageHeader title={'Indicators'} />

        <Card className="mt-4">
          <Card.Body>
            {edittable && (
              <IndicatorButton
                reportId={reportId}
                indicatorIds={indicatorIds}
                fetchData={this.fetchIndicatorTableData}
                year={year}
              />
            )}

            {audiences.map(
              (a) =>
                data.filter((d) => d[1].audience == a).length != 0 && (
                  <IndicatorTable
                    indicatorData={data.filter((d) => d[1].audience == a)}
                    name={a}
                    loading={loading}
                    edittable={edittable}
                    reportId={reportId}
                    year={year}
                    fetchData={this.fetchIndicatorTableData}
                    key={`${a}-table`}
                    className="mt2"
                  />
                )
            )}

            {loading && (
              <div
                style={{
                  height: 150,
                }}
              >
                <Spinner loading={loading} />
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}
