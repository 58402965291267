import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import ItemProgressBar from '../item/progress_bar.jsx';
import Spinner from './absolute.jsx';
import ItemsLongitudinal from './items-longitudinal.jsx'


export default class IndicatorItemModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    indicatorId: PropTypes.number,
    indicatorName: PropTypes.string,
    reportId: PropTypes.number,
    items: PropTypes.array,
    loading: PropTypes.bool,
    withForm: PropTypes.bool.isRequired,
  };

  render() {
    const {
      onHide,
      show,
      reportId,
      items,
      indicatorId,
      indicatorName,
      loading,
      withForm,
    } = this.props;

    return (
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="mt-1 mt-1">{indicatorName} Items</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: '30vh' }}>
          <div className="row">
            <div className="col-12 mt-1">
              {!loading && ( 
                 <ItemProgressBar
                   items={items}
                   withForm={withForm}
                   formType="indicatorTable"
                   reportId={reportId}
                   indicatorId={indicatorId}
                 />
               )}
              
              {loading && <Spinner loading={loading} />}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
