import React from "react";
import PropTypes from "prop-types";
import { Table } from "react-bootstrap";
import { formatAudience } from "../helper.jsx";

Array.prototype.isNull = function () {
  return this.join().replace(/,/g, "").length === 0;
};

export default class ResponseRateTable extends React.Component {
  static propTypes = {
    responseRates: PropTypes.array.isRequired,
  };

  render() {
    const { responseRates } = this.props;
    const years = [...new Set(responseRates.map((y) => y.year).sort())]
    const audiences = [...new Set(responseRates.map((a) => a.audience).sort())]

    const findResponseCount = (findYear, findAudience) => {
      const rate = responseRates.find( ({ year, audience }) => (year === findYear && audience === findAudience))
      if (rate === undefined) {
        return '-'
      } else {
        return parseInt(rate.count)
      }
    }

    return (
      <div>
        <Table bordered className='shadow-sm'>
          <thead>
            <tr>
              <th colSpan="100%" className="text-center">
                Response Information
              </th>
            </tr>
            <tr>
              <th className="text-center">Respondent</th>
              {years.map((y) => (
                <th key={`column-header-${y}`}>{y}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {audiences.map((a) => (
              <tr key={`row-${a}`}>
                <td>{formatAudience(a)}</td>
                {years.map((y) => (
                  <td key={`column-${a}-${y}`}>{findResponseCount(y, a)}</td>
                ))
                }
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  }
}
