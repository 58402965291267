import React from 'react';
import PropTypes from 'prop-types';
import { post, put } from '../../../../../utils/ajax';
import { Button } from 'react-bootstrap';
import { alert } from '../../../../../utils/alert';

export default class IntroductionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.introduction.title || '',
      contents: props.introduction.contents || '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.props.introduction.id) {
      this.handleUpdate();
      this.props.onHide();
    } else {
      this.handleCreate();
    }
  }

  handleCreate() {
    post(`/api/web/reports/${this.props.reportId}/introductions`, {
      introduction: {
        title: this.state.title,
        contents: this.state.contents,
      },
      authenticity_token: document.querySelector('meta[name=csrf-token]')
        .content,
    })
      .catch(() => {
        alert(
          'There was an error creating the introduction section.',
          'danger'
        );
      })
      .then(() => {
        document.getElementById('introduction-form').reset();
        this.setState({ title: '', contents: '', isHidden: true });
        this.props.fetchData();
      });
  }

  handleUpdate() {
    put(`/api/web/introductions/${this.props.introduction.id}`, {
      introduction: {
        title: this.state.title,
        contents: this.state.contents,
      },
      authenticity_token: document.querySelector('meta[name=csrf-token]')
        .content,
    })
      .catch(() => {
        alert(
          'There was an error creating the introduction section.',
          'danger'
        );
      })
      .then(() => {
        this.props.fetchData();
      });
  }

  render() {
    const { title, contents } = this.state;

    return (
      <div>
        <form onSubmit={this.handleSubmit} id="introduction-form">
          <div className="form-group mt-2">
            <input
              type="text"
              className="form-control"
              placeholder="Introduction Section Title (optional)"
              name="title"
              onChange={this.handleChange}
              value={title}
            />
          </div>

          <div className="form-group mt-4">
            <textarea
              className="form-control"
              placeholder="Introduction Section Contents"
              name="contents"
              rows="15"
              onChange={this.handleChange}
              value={contents}
            />
          </div>
          <div className="d-flex justify-content-center">
            <Button type="submit" className="btn-light mt-4 mb-2">
              <i className="fa fa-save text-primary me-1" />
              Introduction Section
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
