import React from "react";
import { RainbowSpinner } from "react-spinners-kit";

class Spinner extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { loading } = this.props;

    const styles = {
      container: {
        marginTop: "5em",
        position: "absolute",
        display: "flex",
        left: 0,
        justifyContent: "center",
        width: "100%",
        zIndex: "10"
      }
    };

    return (
      <div className="AbsoluteSpinner" style={{ ...styles.container }}>
        <RainbowSpinner size={75} color="#C12F18" loading={loading} />
      </div>
    );
  }
}

export default Spinner;
