import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import SectionForm from './section_form.jsx';
import SectionAction from './section_action.jsx';
import SectionModal from './modal.jsx';
import ResponseRateTable from './response-rate-table.jsx';
import { get, destroy } from '../../../../../utils/ajax';
import { alert } from '../../../../../utils/alert';
import Spinner from '../indicator/absolute.jsx';
import PageHeader from '../../shared/PageHeader.jsx';

class Methods extends React.Component {
  static propTypes = {
    reportId: PropTypes.number.isRequired,
    edittable: PropTypes.bool.isRequired,
  };

  state = {
    formHidden: true,
    modalOpen: false,
    sections: [],
    responseRates: [],
    reportSection: {},
    buttonText: 'Add Section',
    loading: false,
  };

  fetchReportSections = () => {
    this.setState({ loading: true });
    get(`/api/web/reports/${this.props.reportId}/report_sections`)
      .catch(() => {
        alert('There was an error retrieving the methods', 'danger');
      })
      .then((res) => {
        this.setState({
          sections: res.sections,
          responseRates: res.response_rates,
          loading: false,
        });
      });
  };

  handleModalHide = () => {
    this.setState({ modalOpen: false });
  };

  openModal = (section) => {
    this.setState({ modalOpen: true, reportSection: section });
  };

  refreshSection = () => {
    this.setState({
      formHidden: true,
      sections: [],
    });
    this.fetchReportSections();
  };

  componentDidMount() {
    this.fetchReportSections();
  }

  destroySection = (sectionId) => {
    destroy(`/api/web/report_sections/${sectionId}`, {
      authenticity_token: document.querySelector('meta[name=csrf-token]')
        .content,
    })
      .catch((err) => {
        showError('Something went wrong!', err);
      })
      .then(() => this.refreshSection());
  };

  toggleHidden = () => {
    this.setState({
      formHidden: !this.state.formHidden,
      buttonText:
        this.state.buttonText == 'Add Section' ? 'Hide Form' : 'Add Section',
    });
  };

  render() {
    const {
      sections,
      formHidden,
      buttonText,
      modalOpen,
      reportSection,
      responseRates,
      loading,
    } = this.state;
    const tableClass = loading ? 'faded' : '';
    const { reportId, edittable } = this.props;

    return (
      <div className='mb-5'>
        <PageHeader title={'Methods'} />
        <Card>
          <Card.Body>
            {loading && <Spinner loading={loading} />}
            {!!sections &&
              sections.map((section) => (
                <div key={section.id}>
                  <div className="row">
                    <div className="col-12">
                      <h3>
                        {section.title}
                        {edittable && (
                          <div className="btn-group float-end">
                            <button
                              type="button"
                              onClick={() => this.openModal(section)}
                              className="mb-05 btn btn-sm btn-light"
                            >
                              <i className="fa fa-edit text-warning me-1" />
                              Edit
                            </button>
                            <SectionAction
                              sectionId={section.id}
                              destroy={this.destroySection}
                            />
                          </div>
                        )}
                      </h3>
                      <p className="lead">{section.contents}</p>
                    </div>
                  </div>
                </div>
              ))}

            <SectionModal
              show={modalOpen}
              onHide={this.handleModalHide}
              reportSection={reportSection}
              fetchData={this.fetchReportSections}
              reportId={reportId}
            />

            {edittable && (
              <Button
                variant={'light'}
                onClick={this.toggleHidden}
                className="mt-1 mt-1"
              >
                <i className="fa fa-plus text-primary me-1" />
                {buttonText}
              </Button>
            )}

            {!formHidden && (
              <div className="my-4">
                <Card className="shadow-sm">
                  <Card.Header>Add New Section to Methods</Card.Header>
                  <Card.Body>
                    <SectionForm
                      reportId={reportId}
                      reportSection={{}}
                      fetchData={this.fetchReportSections}
                    />
                  </Card.Body>
                </Card>
              </div>
            )}

            {!!responseRates && (
              <div className="d-flex justify-content-center">
                <div className={`${tableClass}`}>
                  <ResponseRateTable responseRates={responseRates} />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default Methods;
