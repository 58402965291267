export function calculateColor(value) {
  if (value == 0 || null) {
    return '#f8f9fa';
  } else if (value >= 5) {
    return "#ECF1E1";
  } else if (value > 4.25) {
    return '#fdf8e7';
  } else if (value <= 4.25) {
    return "#fbe1dd";
  }
}

export function calculateCellValue(value) {
  if (value == null) {
    return "-";
  } else {
    return Math.floor(value * 100) / 100;
  }
}

export function colorByIndex(index) {
  switch (index) {
    case 0:
      return "#1887D2";
    case 1:
      return "#E5A014";
    case 2:
      return "#A2B969";
    case 3:
      return "#E75A14";
    default:
    return "#C12F18";
  }
}
