import React from 'react'
import PropTypes from 'prop-types'
import { post } from '../../../../../utils/ajax'
import { Button, Modal } from 'react-bootstrap'
import Select from 'react-select'
import { alert } from '../../../../../utils/alert'

 export default class CapacityFormModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    reportId: PropTypes.number.isRequired,
    capacities: PropTypes.array,
    fetchReport: PropTypes.func.isRequired
  }

  state = {
    selectedOption: null,
  }

   handleChange = (selectedOption) => {
    this.setState({ selectedOption })
  }

   handleSubmit = event => {
    const { onHide, fetchReport } = this.props
    event.preventDefault()

     post(`/api/web/reports/${this.props.reportId}/report_capacities`, {
      report_capacity: {
        capacity_id: this.state.selectedOption.value
      },
      authenticity_token: document.querySelector('meta[name=csrf-token]')
        .content
    })
      .catch(() => {
        alert('There was an error creating the report capacity.', 'danger')
      })
      .then(() => {
        onHide()
        this.setState({ selectedOption: null })
        this.fetchReport()
      })
  }

   render() {
    const { onHide, show, capacities } = this.props;
    const { selectedOption } = this.state;

     return (
       <Modal show={show} onHide={onHide} size="lg">
         <Modal.Header closeButton>
           <Modal.Title>Add Capacity to Report</Modal.Title>
         </Modal.Header>
         <Modal.Body>
           <form onSubmit={this.handleSubmit}>
             <Select
               value={selectedOption}
               options={capacities}
               onChange={(val) => this.handleChange(val)}
             />
             <Button type="submit" className="mt2">
               Add Capacity
             </Button>
           </form>
         </Modal.Body>
       </Modal>
     );
  }
}
