import React from "react";
import PropTypes from "prop-types";
import {put} from "../../../../../utils/ajax";
import {Button, Modal} from "react-bootstrap";
import Select from "react-select";
import { alert } from '../../../../../utils/alert';

export default class ItemModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    reportConstructId: PropTypes.number.isRequired,
    updateCapacity: PropTypes.func.isRequired,
    items: PropTypes.array
  };

  state = {
    selectedOption: null
  };

  handleChange = selectedOption => {
    this.setState({selectedOption});
  };

  handleSubmit = event => {
    const {onHide, updateCapacity, reportConstructId} = this.props;
    event.preventDefault()

    put(`/api/web/report_items/${this.state.selectedOption.value}`, {
      report_item: {
        display: true
      },
      authenticity_token: document.querySelector("meta[name=csrf-token]").content
    }).catch(() => {
      alert("There was an error adding the donut chart", "danger");
    }).then(() => {
      onHide();
      this.setState({selectedOption: null});
      updateCapacity();
    });
  };

  render() {
    const {onHide, show, items} = this.props;
    const {selectedOption} = this.state;

    return (
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header>
          <Modal.Title>Add Item to Report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={this.handleSubmit}>
            <div className="form-group">
              <Select
                value={selectedOption}
                options={items}
                onChange={(val) => this.handleChange(val)}
              />
            </div>
            <Button type="submit" className="btn-light  mt-2">
              <i className="fa fa-plus text-primary"></i>
              Add Item
            </Button>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
}
