import { mapKeys, snakeCase } from 'lodash'
import moment from 'moment'

export function convertKeysToSnake(obj) {
  return mapKeys(obj, (_v, key) => snakeCase(key))
}

// initializes clazz when page is loaded with the
// matching bodyClass
export function runPage(clazz, bodyClass) {
  $(document).on('turbolinks:load', () => {
    if (document.body.classList.contains(bodyClass)) {
      new clazz()
    }
  })
}

export function convertBoolsToYesNo(obj) {
  obj = { ...obj }
  Object.keys(obj).forEach(key => {
    if (obj[key] === true) {
      obj[key] = 'Yes'
    } else if (obj[key] === false) {
      obj[key] = 'No'
    }
  })

  return obj
}

export function titleize(sentence) {
  if (!sentence || !sentence.split) return sentence

  const _titleizeWord = string =>
    string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()

  sentence = sentence.replace(/_/g, ' ')
  const result = sentence.split(' ').map(word => _titleizeWord(word))

  return result.join(' ')
}

const calculateCurrentSchoolYear = () => {
  const today = moment()
  if (today.month() < 7) {
    // Jan through Jul is current year
    return today.year()
  } else {
    return today.year() + 1
  }
}

export const currentSchoolYear = calculateCurrentSchoolYear()
