import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import ConstructItemForm from '../construct/item-form.jsx';
import IndicatorTableItemForm from '../indicator/table-item-form.jsx';
import ItemsLongitudinal from '../indicator/items-longitudinal.jsx';

export default class ItemProgressBar extends React.Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    currentItemIds: PropTypes.array,
    reportConstructId: PropTypes.number,
    reportId: PropTypes.number,
    indicatorId: PropTypes.number,
    withForm: PropTypes.bool.isRequired,
    formType: PropTypes.string,
  };

  calcPercent = (part, whole) => {
    return (part * 100) / whole;
  };

  percentFavorable = (item) => {
    const favorable = item.inverse
      ? item.answers.one.count + item.answers.two.count
      : item.answers.five.count + item.answers.six.count;
    return Math.round((favorable * 100) / item.total);
  };

  calcBar = (item, number) => {
    const newNumb = item.inverse ? this.flipNumber(number) : number;

    return this.calcPercent(item.answers[newNumb].count, item.total);
  };

  calcTableData = (item, number, dataPoint) => {
    const newNumb = item.inverse ? this.flipNumber(number) : number;
    if (dataPoint === 'count') {
      return item.answers[newNumb].count;
    } else {
      return item.answers[newNumb].scale;
    }
  };

  flipNumber = (number) => {
    switch (number) {
      case 'one':
        return 'six';
      case 'two':
        return 'five';
      case 'three':
        return 'four';
      case 'four':
        return 'three';
      case 'five':
        return 'two';
      case 'six':
        return 'one';
    }
  };

  render() {
    const {
      items,
      reportConstructId,
      currentItemIds,
      indicatorId,
      withForm,
      formType,
      reportId,
    } = this.props;
    return (
      <div>
        {!!items &&
          items.map((i) => (
            <div key={i.item_id} className="my-4">
              <Row>
                {withForm && (
                  <Col xs={2} md={2}>
                    {formType === 'construct' && (
                      <ConstructItemForm
                        itemId={i.item_id}
                        reportItemId={i.report_item_id}
                        reportConstructId={reportConstructId}
                        currentItemIds={currentItemIds}
                      />
                    )}
                    {formType === 'indicatorTable' && (
                      <IndicatorTableItemForm
                        itemId={i.item_id}
                        indicatorId={indicatorId}
                        reportId={reportId}
                        indicatorTableItemId={i.indicator_table_id}
                      />
                    )}
                  </Col>
                )}
                <Col xs={withForm ? 10 : 12} sm={withForm ? 10 : 12}>
                  <h4>
                    {i.question}
                    <div className="text-muted fw-light text-sm">
                      <span className="text-secondary fw-bolder">
                        {this.percentFavorable(i)}%
                      </span>
                      &nbsp;of the&nbsp;
                      <span>{i.total}</span>&nbsp;responses were favorable
                    </div>
                  </h4>
                </Col>
              </Row>
              <Row className="mt-2 mb-4">
                <Col xs={12} md={12}>
                  <ItemsLongitudinal itemId={i.item_id} reportId={reportId} />
                </Col>
              </Row>
              <hr />
            </div>
          ))}
      </div>
    );
  }
}
