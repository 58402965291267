import React from 'react';
import PropTypes from 'prop-types';
import { get, destroy } from '../../../../../utils/ajax';
import ConstructButton from '../construct/button.jsx';
import Construct from '../construct/index.jsx';
import YearlyChart from './yearly_chart.jsx';
import BarChart from './bar_chart.jsx';
import Logo from '../../../../../../assets/images/report_image.png';
import CapacityAction from './action.jsx';
import { alert } from '../../../../../utils/alert';
import { colorByIndex } from '../../../../../utils/color-class';
import { createXCategories } from './chart-helpers.jsx';
import { Card } from 'react-bootstrap';
import PageHeader from '../../shared/PageHeader.jsx';
import OrderConstructs from './order-constructs.jsx';
import { orderBy } from 'lodash';

class Capacity extends React.Component {
  static propTypes = {
    capacityName: PropTypes.string.isRequired,
    capacityId: PropTypes.number.isRequired,
    reportId: PropTypes.number.isRequired,
    reportCapacityId: PropTypes.number.isRequired,
    edittable: PropTypes.bool.isRequired,
    fetchReport: PropTypes.func.isRequired,
  };

  state = {
    capacity: [],
    reportConstructs: [],
  };

  fetchCapacity = () => {
    get(`/api/web/report_capacities/${this.props.reportCapacityId}`)
      .then((res) => {
        this.setState({
          capacity: res.capacity,
          reportConstructs: res.report_constructs,
        });
      })
      .catch(() => {
        alert('There was an error retrieving the constructs.', 'danger');
      });
  };

  handleDestroy = (reportCapacityId) => {
    const { fetchReport } = this.props;
    destroy(`/api/web/report_capacities/${reportCapacityId}`, {
      authenticity_token: document.querySelector('meta[name=csrf-token]')
        .content,
    })
      .then((res) => {
        fetchReport();
        alert('Capacity removed', 'success');
      })
      .catch(() => {
        alert(
          'There was an error deleting the capacity from this report.',
          'danger'
        );
      });
  };

  componentDidMount() {
    this.fetchCapacity();
  }

  render() {
    const { capacityName, reportCapacityId, reportId, edittable, fetchReport } = this.props;
    const { capacity, reportConstructs } = this.state;

    return (
      <>
        <Card className="mt-4">
          <Card.Body>
            <div className="row">
              <div className="col-12">
                <PageHeader
                  title={
                    <span>
                      <img
                        src={Logo}
                        className="img-fluid"
                        style={{ maxHeight: '100px' }}
                      />
                      <span className="ms-4">
                        {capacityName}{' '}
                        {edittable && (
                          <OrderConstructs
                            reportConstructs={reportConstructs}
                            fetchReport={fetchReport}
                          />
                        )}
                        {edittable && (
                          <CapacityAction
                            reportCapacityId={reportCapacityId}
                            destroy={this.handleDestroy}
                          />
                        )}
                      </span>
                    </span>
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p style={{ fontSize: '16px' }}>
                  {!!capacity && capacity.description}
                </p>
                <Card>
                  <Card.Body>
                    {!!reportConstructs[0] &&
                      createXCategories(
                        reportConstructs.map((d) =>
                          d.yearly_chart.map((d) => d[0])
                        )
                      ).length > 1 && <YearlyChart data={reportConstructs} />}
                    {!!reportConstructs[0] &&
                      createXCategories(
                        reportConstructs.map((d) =>
                          d.yearly_chart.map((d) => d[0])
                        )
                      ).length == 1 && <BarChart data={reportConstructs} />}
                  </Card.Body>
                </Card>
              </div>
            </div>
            <div className="row my-4">
              <div className="col-1" />
              <div className="col-10"></div>
            </div>

            {!!reportConstructs &&
              orderBy(reportConstructs, ['number'], ['asc']).map((c, index) => (
                <div key={`${reportCapacityId}-construct-${c.id}`}>
                  <Construct
                    fetchCapacity={this.fetchCapacity}
                    construct={c}
                    reportId={reportId}
                    itemColor={colorByIndex(index)}
                    index={index}
                    edittable={edittable}
                  />
                </div>
              ))}

            {edittable && (
              <ConstructButton
                reportCapacityId={reportCapacityId}
                updateCapacity={this.fetchCapacity}
              />
            )}
          </Card.Body>
        </Card>
      </>
    );
  }
}

export default Capacity;
