import React from 'react';
import Card from 'react-bootstrap/Card'
import PageHeader from '../../shared/PageHeader.jsx';
import ListGroup from 'react-bootstrap/ListGroup'
import UpdateCapacityOrder from './update-capacity-order.jsx'
import { orderBy } from 'lodash'

function OrderCapacities({
  reportCapacities,
  fetchReport,
  capacities,
}) {
  
  return (
    <>
      <PageHeader title={'Order Capacities'} />
      <Card>
        <Card.Body>
          <ListGroup>
            {orderBy(reportCapacities, ['number'], ['asc']).map((r, index) => (
              <ListGroup.Item key={`${r.id}-${index}`}>
                <UpdateCapacityOrder
                  capacities={capacities}
                  reportCapacity={r}
                  fetchReport={fetchReport}
                />
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Card.Body>
      </Card>
    </>
  );
}

export default OrderCapacities;
