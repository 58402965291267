import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

class ItemChart extends React.Component {
  static propTypes = {
    percent: PropTypes.number.isRequired,
    color: PropTypes.string
  };

  render() {
    const { percent, color } = this.props;
    const options = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: "pie",
        height: "100%",
        marginBottom: 0,
        style: {
          fontFamily: "Avenir"
        },
        events: {
          load() {
            this.showLoading();
            setTimeout(this.hideLoading.bind(this), 1000);
          }
        }
      },
      credits: {
        enabled: false
      },
      pie: {
        dataLabels: {
          enabled: true,
          format: "<b>{point.name}</b>: {point.percentage:.1f} %"
        }
      },
      title: {
        text: `${percent}%`,
        style: { fontSize: "1.5rem", fontWeight: "bold" },
        verticalAlign: "middle",
        floating: true
      },
      point: {
        events: {
          legendItemClick: function() {
            return false;
          }
        }
      },
      tooltip: {
        headerFormat: '<div style="font-size:16px;">{point.key}</div><br/>',
        pointFormat: "{data.name} <b>{point.percentage:.1f}%</b>"
      },
      series: [
        {
          name: "",
          colorByPoint: true,
          innerSize: "50%",
          data: [[percent, "Favorable", color], [100 - percent, "", "#eee"]],
          keys: ["y", "name", "color"],
          type: "pie",
          dataLabels: {
            enabled: false
          }
        }
      ]
    };

    return (
      <div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    );
  }
}

export default ItemChart;
