import React from 'react';
import PropTypes from 'prop-types';

const IntroductionAction = ({ introductionId, destroy }) => {
  const handleDestroy = (_) => {
    destroy(introductionId);
  };

  return (
    <button
      type="button"
      className="btn btn-sm btn-light border-light me-1"
      onClick={handleDestroy}
    >
      <i className="fa fa-trash text-danger" aria-hidden="true" /> Delete
    </button>
  );
};

IntroductionAction.propTypes = {
  introductionId: PropTypes.number.isRequired,
  destroy: PropTypes.func.isRequired,
};

export default IntroductionAction;
