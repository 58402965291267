import React from "react";
import PropTypes from "prop-types";
import { get } from "../../../../../utils/ajax";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import { alert } from "../../../../../utils/alert";
import Spinner from "../indicator/absolute.jsx";
import ItemProgressBar from "../item/progress_bar.jsx"

export default class ItemPickerModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    reportConstructId: PropTypes.number.isRequired,
    fetchData: PropTypes.func.isRequired,
    indicators: PropTypes.array.isRequired,
    itemIds: PropTypes.array.isRequired,
    reportId: PropTypes.number.isRequired
  };

  state = {
    selectedOption: null,
    loading: false,
    items: []
  };

  handleChange = selectedOption => {
    this.setState({ selectedOption: selectedOption, loading: true });
    this.fetchIndicatorItems(selectedOption.value)
  };

  fetchIndicatorItems = indicatorId => {
    this.setState({ items: [] })
    const { reportConstructId, reportId } = this.props
    get(`/api/web/district_report_items`, {
      params: {
        report_construct_id: reportConstructId,
        indicator_id: indicatorId,
        report_id: reportId,
        all_items: true
      }
    })
    .catch(() => {
      alert("There was an error fetching the indicator items.", "danger");
    })
    .then(res => {
      this.setState({items: res, loading: false})
    });
  }



  render() {
    const { onHide, show, indicators, reportConstructId, itemIds, reportId } = this.props;
    const { selectedOption, loading, items } = this.state;
    
    return (
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Item to Report Construct</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ minHeight: 350 }}>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <Select
                  value={selectedOption}
                  options={indicators}
                  onChange={val => this.handleChange(val)}
                />
              </div>
              <ItemProgressBar items={items} reportId={reportId} reportConstructId={reportConstructId} currentItemIds={itemIds} withForm={true} formType='construct' />
              <div className="mt-2 text-center">
                {loading &&
                <Spinner loading={loading} />
                }
                {!loading && !!items && items.length == 0 &&
                  <p>Pick an indicator to add items</p>
                }
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
