import React from 'react';
import PropTypes from 'prop-types';
import { post } from '../../../../../utils/ajax';
import { Modal, Table } from 'react-bootstrap';
import Spinner from './absolute.jsx';
import { alert } from '../../../../../utils/alert';
import IndicatorForm from './form.jsx';
import {
  calculateCellValue,
  calculateColor,
} from '../../../../../utils/color-class';
import { formatAudience } from '../helper.jsx';

export default class IndicatorModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    indicatorData: PropTypes.array.isRequired,
    reportId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    indicatorIds: PropTypes.array.isRequired,
    year: PropTypes.number,
  };

  state = {
    selectedOption: null,
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };

  handleSubmit = (event) => {
    const { onHide } = this.props;
    event.preventDefault();

    post('/api/web/report_indicators', {
      report_indicator: {
        report_id: this.props.reportId,
        indicator_id: this.state.selectedOption.value,
      },
      authenticity_token: document.querySelector('meta[name=csrf-token]')
        .content,
    })
      .catch(() => {
        alert('There was an error adding the indicator', 'danger');
      })
      .then(() => {
        onHide();
        this.setState({ selectedOption: null });
      });
  };

  render() {
    const {
      onHide,
      show,
      indicatorData,
      loading,
      reportId,
      indicatorIds,
      year,
    } = this.props;
    const { selectedOption } = this.state;

    return (
      <Modal show={show} onHide={onHide} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Possible Indicators</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Table striped bordered condensed>
              <thead>
                <tr>
                  <th></th>
                  <th>Audience</th>
                  <th>Indicator</th>
                  <th>{year - 3}</th>
                  <th>{year - 2}</th>
                  <th>{year - 1}</th>
                  <th>{year}</th>
                </tr>
              </thead>
              <tbody>
                {indicatorData.map((indicator) => (
                  <tr key={indicator[0]}>
                    <td>
                      <IndicatorForm
                        reportId={reportId}
                        indicatorId={indicator[0]}
                        indicatorAdded={indicatorIds.includes(indicator[0])}
                      />
                    </td>
                    <td>{formatAudience(indicator[1].audience)}</td>
                    <td>{indicator[1].name}</td>
                    <td
                      style={{
                        backgroundColor: calculateColor(
                          indicator[1].data[year - 3]
                        ),
                      }}
                    >
                      {calculateCellValue(indicator[1].data[year - 3])}
                    </td>
                    <td
                      style={{
                        backgroundColor: calculateColor(
                          indicator[1].data[year - 2]
                        ),
                      }}
                    >
                      {calculateCellValue(indicator[1].data[year - 2])}
                    </td>
                    <td
                      style={{
                        backgroundColor: calculateColor(
                          indicator[1].data[year - 1]
                        ),
                      }}
                    >
                      {calculateCellValue(indicator[1].data[year - 1])}
                    </td>
                    <td
                      style={{
                        backgroundColor: calculateColor(
                          indicator[1].data[year]
                        ),
                      }}
                    >
                      {calculateCellValue(indicator[1].data[year])}
                    </td>
                  </tr>
                ))}
                {loading && (
                  <tr style={{ height: 150 }}>
                    <td colSpan="7">
                      <Spinner loading={loading} />
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
