import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import IndicatorModal from "./modal.jsx";
import { get } from '../../../../../utils/ajax';
import { alert } from '../../../../../utils/alert';

class IndicatorButton extends React.Component {
  static propTypes = {
    reportId: PropTypes.number.isRequired,
    indicatorIds: PropTypes.array.isRequired,
    fetchData: PropTypes.func.isRequired,
    year: PropTypes.number
  };

  state = {
    modalOpen: false,
    data: [],
    loading: false
  };

  handleModalHide = () => {
    this.setState({ modalOpen: false });
    this.props.fetchData()
  };

  openModal = () => {
    this.setState({ modalOpen: true, loading: true });
    get(`/api/web/reports/${this.props.reportId}/indicator_table`)
      .catch(() => {
        this.setState({ loading: false });
        alert("There was an error retrieving the indicator table", "danger");
      })
      .then(res => {
        this.setState({ data: res, loading: false });
      });
  };

  render() {
    const { modalOpen, data, loading } = this.state;
    const { reportId, indicatorIds, year } = this.props;
    return (
      <div>
        <IndicatorModal
          show={modalOpen}
          onHide={this.handleModalHide}
          indicatorData={data}
          reportId={reportId}
          loading={loading}
          year={year}
          indicatorIds={indicatorIds}
        />

        <Button onClick={this.openModal} className="btn-light my-2">
          <i className="fa fa-plus text-primary" />
          &nbsp;Add Indicator
        </Button>
      </div>
    );
  }
}

export default IndicatorButton;
