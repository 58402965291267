import React from 'react';
import PropTypes from 'prop-types';
import { post, put } from '../../../../../utils/ajax';
import { Button } from 'react-bootstrap';
import { alert } from '../../../../../utils/alert';

export default class SectionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: props.reportSection.title || '',
      contents: props.reportSection.contents || '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.props.reportSection.id) {
      this.handleUpdate();
      this.props.onHide();
    } else {
      this.handleCreate();
    }
  }

  handleCreate() {
    post(`/api/web/reports/${this.props.reportId}/report_sections`, {
      report_section: {
        title: this.state.title,
        contents: this.state.contents,
      },
      authenticity_token: document.querySelector('meta[name=csrf-token]')
        .content,
    })
      .catch(() => {
        alert('There was an error creating the section.', 'danger');
      })
      .then(() => {
        document.getElementById('section-form').reset();
        this.setState({ title: '', contents: '', isHidden: true });
        this.props.fetchData();
      });
  }

  handleUpdate() {
    put(`/api/web/report_sections/${this.props.reportSection.id}`, {
      report_section: {
        title: this.state.title,
        contents: this.state.contents,
      },
      authenticity_token: document.querySelector('meta[name=csrf-token]')
        .content,
    })
      .catch(() => {
        alert('There was an error creating the methods section.', 'danger');
      })
      .then(() => {
        this.props.fetchData();
      });
  }

  render() {
    const { title, contents } = this.state;

    return (
      <div>
        <form onSubmit={this.handleSubmit} id="section-form">
          <div className="form-group mt-2">
            <input
              type="text"
              className="form-control"
              placeholder="Section Title"
              name="title"
              onChange={this.handleChange}
              value={title}
            />
          </div>

          <div className="form-group mt-4">
            <textarea
              className="form-control"
              placeholder="Section contents"
              name="contents"
              rows="15"
              onChange={this.handleChange}
              value={contents}
            />
          </div>
          <div className="d-flex justify-content-center my-4">
            <Button type="submit" className="btn-light">
              <i className="fa fa-save text-primary me-1"></i>
              Methods Section
            </Button>
          </div>
        </form>
      </div>
    );
  }
}
