import React from 'react'

function PageHeader({ title }) {
  return (
    <>
      <div className="mt-4 mb-2">
        <h2>{title}</h2>
      </div>
      <hr />
    </>
  );
}

export default PageHeader