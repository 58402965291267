import React from "react";
import PropTypes from "prop-types";
import IntroductionForm from "./form.jsx";
import { Modal } from 'react-bootstrap'

export default class IntroductionModal extends React.Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    reportId: PropTypes.number.isRequired,
    introduction: PropTypes.object
  };

  render() {
    const { onHide, show, introduction, reportId } = this.props;

    return (
      <Modal show={show} onHide={onHide} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Update Introduction Section</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <IntroductionForm
            introduction={introduction}
            reportId={reportId}
            fetchData={this.props.fetchData}
            onHide={onHide}
          />
        </Modal.Body>
      </Modal>
    );
  }
}
