import React from "react";
import PropTypes from "prop-types";
import { Table, Button } from "react-bootstrap";
import {
  calculateCellValue,
  calculateColor,
} from '../../../../../utils/color-class';
import IndicatorItemModal from "./items-modal.jsx";
import { get } from '../../../../../utils/ajax';
import { formatAudience } from "../helper.jsx";

class IndicatorTable extends React.Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    edittable: PropTypes.bool.isRequired,
    reportId: PropTypes.number.isRequired,
    fetchData: PropTypes.func.isRequired,
    year: PropTypes.number,
    indicatorData: PropTypes.array
  };

  state = {
    openModal: false,
    items: [],
    indicatorId: 0,
    modalLoading: false,
    withForm: false
  };

  fetchItems = indicatorId => {
    const { reportId } = this.props;
    get("/api/web/district_report_items", {
      params: {
        indicator_id: indicatorId,
        report_id: reportId
      }
    })
      .then(res => {
        this.setState({ items: res, modalLoading: false });
      })
      .catch(() => {
        alert("There was an error retrieving the items.", "danger");
      });
  };

  hideIndicatorItemsModal = _ => {
    const { withForm } = this.state;
    this.setState({
      openModal: false,
      modalLoading: false
    });
    if (withForm) {
      this.props.fetchData();
    }
  };

  openIndicatorItemsModal = (indicatorId, indicatorName, withForm) => {
    const { openModal } = this.state;
    this.setState({
      openModal: true,
      indicatorId: indicatorId,
      indicatorName: indicatorName,
      modalLoading: true,
      withForm: withForm
    });
    this.fetchItems(indicatorId);
    return false;
  };

  render() {
    const {
      name,
      indicatorData,
      edittable,
      reportId,
      year,
      fetchData
    } = this.props;
    const {
      openModal,
      indicatorId,
      items,
      modalLoading,
      withForm,
      indicatorName
    } = this.state;
    const indicatorIds = indicatorData.map(i => i[0]);

    return (
      <div>
        <Table bordered condensed>
          <thead>
            <tr>
              <th colSpan="6" className="active">
                <h4 className="text-center">{`${formatAudience(
                  name
                )} Indicators`}</h4>
              </th>
            </tr>
            <tr className="active">
              {edittable && <th>Edit</th>}
              <th>Indicator</th>
              <th>{year - 3}</th>
              <th>{year - 2}</th>
              <th>{year - 1}</th>
              <th>{year}</th>
            </tr>
          </thead>
          <tbody>
            {indicatorData.map(indicator => (
              <tr key={indicator[0]}>
                {edittable && (
                  <td className="text-center">
                    <Button
                      size="sm"
                      className="btn-light"
                      onClick={() =>
                        this.openIndicatorItemsModal(indicator[0], indicator[1].name, true)
                      }
                    >
                      <i className="fa fa-edit text-warning" /> Edit
                    </Button>
                  </td>
                )}
                <td>
                  <a
                    href="#"
                    onClick={() =>
                      this.openIndicatorItemsModal(
                        indicator[0],
                        indicator[1].name,
                        false
                      )
                    }
                  >
                    {indicator[1].name}
                  </a>
                </td>
                <td
                  style={{ backgroundColor: calculateColor(indicator[1].data[year - 3]) }}
                >
                  {calculateCellValue(indicator[1].data[year - 3])}
                </td>
                <td
                  style={{ backgroundColor: calculateColor(indicator[1].data[year - 2]) }}
                >
                  {calculateCellValue(indicator[1].data[year - 2])}
                </td>
                <td
                  style={{ backgroundColor: calculateColor(indicator[1].data[year - 1]) }}
                >
                  {calculateCellValue(indicator[1].data[year - 1])}
                </td>
                <td
                  style={{ backgroundColor: calculateColor(indicator[1].data[year]) }}
                >
                  {calculateCellValue(indicator[1].data[year])}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <IndicatorItemModal
          show={openModal}
          onHide={this.hideIndicatorItemsModal}
          indicatorId={indicatorId}
          reportId={reportId}
          items={items}
          loading={modalLoading}
          withForm={withForm}
          indicatorName={indicatorName}
        />
      </div>
    );
  }
}

export default IndicatorTable;
