import React from "react";
import PropTypes from "prop-types";

const CapacityAction = ({ reportCapacityId, destroy }) => {
  const handleDestroy = _ => {
    destroy(reportCapacityId)
  }

  return (
    <div style={{display: "inline-block"}}>
      <a className="btn btn-sm btn-light" onClick={handleDestroy}>
        <i className="fa fa-trash text-danger" aria-hidden="true" /> Delete Capacity
      </a>
    </div>
  )
}

CapacityAction.propTypes = {
  reportCapacityId: PropTypes.number.isRequired,
  destroy: PropTypes.func.isRequired
}

export default CapacityAction
