/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Support component names relative to this directory:
// var componentRequireContext = require.context("components", true)
// var ReactRailsUJS = require("react_ujs")
// ReactRailsUJS.useContext(componentRequireContext)
import '@fortawesome/fontawesome-free/js/all';
// import PieChart from '../components/report/shared/charts/example_chart'
// import ExampleChart from '../components/report/shared/charts/example_chart'
//
// WebpackerReact.setup({
//   ExampleChart
// })
import ReactOnRails from 'react-on-rails';
import Title from '../bundles/Reports/components/reports/title_page/index.jsx';  
import Introduction from '../bundles/Reports/components/reports/introduction/index.jsx';
import Indicator from '../bundles/Reports/components/reports/indicator/index.jsx';
import Capacity from '../bundles/Reports/components/reports/capacity/index.jsx';
import Reports from '../bundles/Reports/components/reports/index.jsx';

// This is how react_on_rails knows to handle this component
ReactOnRails.register({
  Title,
  Introduction,
  Indicator,
  Capacity,
  Reports
});