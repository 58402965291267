import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import { get } from '../../../../../utils/ajax';
import HighchartsReact from 'highcharts-react-official';
import Spinner from './absolute.jsx';

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts);
}

function ItemsLongitudinal({ itemId, reportId }) {
  const [item, setItem] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchItem = () => {
    setLoading(true);
    get(`/api/web/district_report_items/${itemId}`, {
      params: {
        report_id: reportId,
      },
    })
      .then((res) => {
        setLoading(false);
        setItem(res);
      })
      .catch(() => {
        alert('There was an error retrieving the item', 'danger');
      });
  };

  const categories = loading ? [] : item.data.map((i) => i.year);

  const one = loading ? [] : item.data.map((i) => i.one);
  const two = loading ? [] : item.data.map((i) => i.two);
  const three = loading ? [] : item.data.map((i) => i.three);
  const four = loading ? [] : item.data.map((i) => i.four);
  const five = loading ? [] : item.data.map((i) => i.five);
  const six = loading ? [] : item.data.map((i) => i.six);

  const oneScale = loading ? '' : item.scale.one;
  const twoScale = loading ? '' : item.scale.two;
  const threeScale = loading ? '' : item.scale.three;
  const fourScale = loading ? '' : item.scale.four;
  const fiveScale = loading ? '' : item.scale.five;
  const sixScale = loading ? '' : item.scale.six;

  const chartTitle = loading ? '' : item.item.question;
  const loadedAndInverse = !loading && item.item.inverse;

  const options = {
    chart: {
      type: 'area',
    },
    title: {
      useHTML: true,
      text: '',
      align: 'left',
    },
    subtitle: {
      text: chartTitle,
    },
    exporting: {
      enabled: true,
      filename: chartTitle,
    },
    credits: {
      enabled: false,
    },
    accessibility: {
      point: {
        valueDescriptionFormat:
          '{index}. {point.category}, {point.y:,.1f} {point.percentage:.1f}%.',
      },
    },
    xAxis: {
      maxPadding: 0,
      minPadding: 0,
      startOnTick: true,
      categories: categories,
    },
    yAxis: {
      labels: {
        format: '{value}%',
      },
      title: {
        enabled: false,
      },
    },
    tooltip: {
      pointFormat:
        '<span style="color:{series.color}">{series.name}</span>: <b>{point.percentage:.1f}%</b> ({point.y:,.0f} responses)<br/>',
      split: true,
    },
    plotOptions: {
      area: {
        stacking: 'percent',
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: loadedAndInverse ? sixScale : oneScale,
        data: loadedAndInverse ? six : one,
        color: '#C12F18',
      },
      {
        name: loadedAndInverse ? fiveScale : twoScale,
        data: loadedAndInverse ? five : two,
        color: '#E7553E',
      },
      {
        name: loadedAndInverse ? fourScale : threeScale,
        data: loadedAndInverse ? four : three,
        color: '#F7931F',
      },
      {
        name: loadedAndInverse ? threeScale : fourScale,
        data: loadedAndInverse ? three : four,
        color: '#FEC31E',
      },
      {
        name: loadedAndInverse ? twoScale : fiveScale,
        data: loadedAndInverse ? two : five,
        color: '#7ACA63',
      },
      {
        name: loadedAndInverse ? oneScale : sixScale,
        data: loadedAndInverse ? one : six,
        color: '#408B2C',
      },
    ],
  };

  return loading ? (
    <Spinner loading={loading} />
  ) : (
    <div className="mt-2">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default ItemsLongitudinal;
