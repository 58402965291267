import React from "react";
import PropTypes from "prop-types";

const ConstructAction = ({ reportConstructId, destroy }) => {
  const handleDestroy = _ => {
    destroy(reportConstructId)
  }

  return (
    <>
      <a className="btn btn-sm btn-light" onClick={handleDestroy}>
        <i className="fa fa-trash text-danger" aria-hidden="true" /> Delete
      </a>
    </>
  )
}

ConstructAction.propTypes = {
  reportConstructId: PropTypes.number.isRequired,
  destroy: PropTypes.func.isRequired
}

export default ConstructAction
