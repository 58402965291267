import React from "react";
import PropTypes from "prop-types";
import { post, destroy } from '../../../../../utils/ajax';
import { alert } from '../../../../../utils/alert';

export default class ConstructItemForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static propTypes = {
    itemId: PropTypes.number.isRequired,
    reportConstructId: PropTypes.number.isRequired,
    currentItemIds: PropTypes.array.isRequired,
    reportItemId: PropTypes.number
  };

  state = {
    itemEnabled: this.props.currentItemIds.includes(this.props.itemId)
  };

  handleSubmit = event => {
    const { itemEnabled } = this.state;
    event.preventDefault();

    if (itemEnabled === false) {
      this.handleCreate();
    } else {
      this.handleDestroy();
    }
  };

  handleCreate = _ => {
    const { itemId, reportConstructId } = this.props;
    const { itemEnabled } = this.state;
    event.preventDefault();

    post("/api/web/report_items", {
      report_item: {
        report_construct_id: reportConstructId,
        item_id: itemId
      },
      authenticity_token: document.querySelector("meta[name=csrf-token]")
        .content
    })
      .catch(() => {
        alert("There was an error adding the item.", "danger");
      })
      .then(() => {
        this.setState({ itemEnabled: !itemEnabled });
      });
  };

  handleDestroy = _ => {
    const { reportItemId } = this.props;

    destroy(`/api/web/report_items/${reportItemId}`, {
      authenticity_token: document.querySelector("meta[name=csrf-token]")
        .content
    })
      .then(res => {
        this.setState({ itemEnabled: false });
        alert("Item removed", "success");
      })
      .catch(() => {
        alert(
          "There was an error deleting the item from this report.",
          "danger"
        );
      });
  };

  render() {
    const { itemEnabled } = this.state;

    return (
      <div>
        <form>
          <div className="form-check form-switch">
            <input
              style={{ cursor: 'pointer' }}
              className="form-check-input"
              checked={itemEnabled}
              onChange={this.handleSubmit}
              type="checkbox"
              role="switch"
            />
          </div>
        </form>
      </div>
    );
  }
}
