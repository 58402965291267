import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ConstructModal from './modal.jsx';
import { get } from '../../../../../utils/ajax';
import { alert } from '../../../../../utils/alert';

export default class ConstructButton extends React.Component {
  static propTypes = {
    reportCapacityId: PropTypes.number.isRequired,
    updateCapacity: PropTypes.func,
  };

  state = {
    modalOpen: false,
    constructs: [],
  };

  handleModalHide = () => {
    this.setState({ modalOpen: false });
  };

  openModal = () => {
    this.setState({ modalOpen: true });
    get('/api/web/constructs')
      .then((res) => {
        this.setState({
          constructs: res.map((data) => ({ value: data.id, label: data.name })),
        });
      })
      .catch(() => {
        alert('There was an error retrieving the constructs.', 'danger');
      });
  };

  render() {
    const { constructs, modalOpen } = this.state;
    const { reportCapacityId, updateCapacity } = this.props;

    return (
      <>
        <ConstructModal
          show={modalOpen}
          onHide={this.handleModalHide}
          constructs={constructs}
          reportCapacityId={reportCapacityId}
          updateCapacity={updateCapacity}
        />
        <div className="row  mt-4">
          <div className="col-12">
            <Button onClick={this.openModal} className="btn-sm btn-light">
              <i className="fa fa-plus text-danger me-1"></i>
              &nbsp; Construct
            </Button>
          </div>
        </div>
      </>
    );
  }
}
