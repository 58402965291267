import React from 'react';
import PropTypes from 'prop-types';
import { post, destroy } from '../../../../../utils/ajax';
import { alert } from '../../../../../utils/alert';

export default class IndicatorForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  static propTypes = {
    indicatorId: PropTypes.number.isRequired,
    reportId: PropTypes.number.isRequired,
    indicatorAdded: PropTypes.bool.isRequired,
  };

  state = {
    currentlyChecked: this.props.indicatorAdded,
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const currentlyChecked = !this.state.currentlyChecked;

    if (currentlyChecked === true) {
      this.handleCreate();
    } else if (currentlyChecked === false) {
      this.handleDestroy();
    }
  };

  handleCreate = (_) => {
    const { reportId, indicatorId } = this.props;
    const { currentlyChecked } = this.state;
    post(`/api/web/report_indicators`, {
      report_indicator: {
        indicator_id: indicatorId,
        report_id: reportId,
      },
      authenticity_token: document.querySelector('meta[name=csrf-token]')
        .content,
    })
      .catch(() => {
        alert('There was an error adding the indicator.', 'danger');
      })
      .then(() => {
        this.setState({ currentlyChecked: !currentlyChecked });
      });
  };

  handleDestroy = (_) => {
    const { reportId, indicatorId } = this.props;
    const { currentlyChecked } = this.state;
    destroy(`/api/web/report_indicators/${indicatorId}`, {
      report_indicator: {
        indicator_id: indicatorId,
        report_id: reportId,
      },
      authenticity_token: document.querySelector('meta[name=csrf-token]')
        .content,
    })
      .then(() => {
        this.setState({ currentlyChecked: !currentlyChecked });
      })
      .catch(() => {
        alert(
          'There was an error deleting the indicator from this report.',
          'danger'
        );
      });
  };

  render() {
    const { currentlyChecked } = this.state;

    return (
      <div>
        <form>
          <div className="form-check form-switch">
            <input
              style={{ cursor: 'pointer' }}
              className="form-check-input"
              checked={currentlyChecked}
              onChange={this.handleSubmit}
              type="checkbox"
              role="switch"
            />
          </div>
        </form>
      </div>
    );
  }
}
