import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import IntroductionForm from './form.jsx';
import IntroductionAction from './action.jsx';
import IntroductionModal from './modal.jsx';
import { get, destroy } from '../../../../../utils/ajax';
import { alert } from '../../../../../utils/alert';
import PageHeader from '../../shared/PageHeader.jsx';

class Introduction extends React.Component {
  static propTypes = {
    reportId: PropTypes.number.isRequired,
    edittable: PropTypes.bool.isRequired,
  };

  state = {
    formHidden: true,
    modalOpen: false,
    isSuccess: false,
    introductions: [],
    editIntroduction: {},
    buttonText: 'Add Introduction',
  };

  fetchIntroductions = () => {
    get(`/api/web/reports/${this.props.reportId}/introductions`)
      .catch(() => {
        alert('There was an error retrieving the introduction', 'danger');
      })
      .then((res) => {
        this.setState({ introductions: res });
      });
  };

  handleModalHide = () => {
    this.setState({ modalOpen: false });
  };

  openModal = (introduction) => {
    this.setState({ modalOpen: true, editIntroduction: introduction });
  };

  refreshIntroduction = () => {
    this.setState({
      formHidden: true,
      introductions: [],
    });
    this.fetchIntroductions();
  };

  componentDidMount() {
    this.fetchIntroductions();
  }

  destroyIntroduction = (introductionId) => {
    destroy(`/api/web/introductions/${introductionId}`, {
      authenticity_token: document.querySelector('meta[name=csrf-token]')
        .content,
    })
      .catch((err) => {
        showError('Something went wrong!', err);
      })
      .then(() => this.refreshIntroduction());
  };

  toggleHidden = () => {
    this.setState({
      formHidden: !this.state.formHidden,
      buttonText:
        this.state.buttonText == 'Add Introduction'
          ? 'Hide Form'
          : 'Add Introduction',
    });
  };

  render() {
    const {
      introductions,
      formHidden,
      buttonText,
      modalOpen,
      editIntroduction,
    } = this.state;
    const { reportId, edittable } = this.props;

    return (
      <div>
        <PageHeader title={'Introduction'} />
        <Card>
          <Card.Body>
            {!!introductions &&
              introductions.map((introduction) => (
                <div key={introduction.id}>
                  <div className="row">
                    <div className="col-12">
                      <h3>
                        {introduction.title}
                        {edittable && (
                          <div className="btn-group float-end">
                            <button
                              type="button"
                              onClick={() => this.openModal(introduction)}
                              className="mb-05 btn btn-sm btn-light"
                            >
                              <i className="fa fa-edit text-warning me-1" />{' '}
                              Edit
                            </button>
                            <IntroductionAction
                              introductionId={introduction.id}
                              destroy={this.destroyIntroduction}
                            />
                          </div>
                        )}
                      </h3>
                      <p className="lead">{introduction.contents}</p>
                    </div>
                  </div>
                </div>
              ))}

            <IntroductionModal
              show={modalOpen}
              onHide={this.handleModalHide}
              introduction={editIntroduction}
              fetchData={this.fetchIntroductions}
              reportId={reportId}
            />

            {edittable && (
              <Button
                variant={'light'}
                onClick={this.toggleHidden}
                className="mb-2"
              >
                {buttonText === 'Add Introduction' && (
                  <span>
                    <i className="fa fa-plus text-primary me-1" />
                  </span>
                )}
                {buttonText}
              </Button>
            )}

            {!formHidden && (
              <div>
                <Card className="shadow-sm">
                  <Card.Header>Add New Section to Introduction</Card.Header>
                  <Card.Body>
                    <IntroductionForm
                      reportId={reportId}
                      introduction={{}}
                      fetchData={this.fetchIntroductions}
                    />
                  </Card.Body>
                </Card>
              </div>
            )}
          </Card.Body>
        </Card>
      </div>
    );
  }
}

export default Introduction;
