import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {colorByIndex} from "../../../../../utils/color-class"
import { findDataPoint, createXCategories } from "./chart-helpers.jsx"
import sortBy from "lodash/sortBy";

class YearlyChart extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired
  };

  render() {
    const {data} = this.props;
    const xCategories = createXCategories(data.map(d => d.yearly_chart.map(d => d[0])))

    const plotOptions = {
      chart: {
        style: {
          fontFamily: 'Avenir',
        },
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        borderWidth: 0,
      },
      credits: {
        enabled: false,
      },
      title: {
        text: 'Capacity Score Over Time',
      },
      xAxis: {
        categories: xCategories,
      },
      yAxis: {
        title: {
          text: 'Capacity Score',
        },
        min: 1,
        max: 6,
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        plotBands: [
          {
            label: 'Low Capacity',
            from: 0,
            to: 4,
            color: '#F4DAD6',
          },
          {
            label: 'Moderate Capacity',
            from: 4,
            to: 5,
            color: '#FDE8CF',
          },
          {
            label: 'Strong Capacity',
            from: 5,
            to: 6,
            color: '#E7F2E4',
          },
        ],
      },
      legend: {
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        borderWidth: 0,
      },
      tooltip: {
        valueDecimals: 2,
        shared: true,
      },
      series: sortBy(data, ['number']).map((d, i) => ({
        name: d.construct.name,
        data: xCategories.map((year) => findDataPoint(year, d.yearly_chart)),
        color: colorByIndex(i),
      })),
    };

    return (<div>
      <HighchartsReact highcharts={Highcharts} options={plotOptions}/>
    </div>);
  }
}

export default YearlyChart;
