import React from 'react';
import PropTypes from 'prop-types';
import CoverLogo from '../../../../../../assets/images/title_page.png';
import Card from 'react-bootstrap/Card';
export default class TitlePage extends React.Component {
  static propTypes = {
    schools: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
  };

  render() {
    const { schools, year, title, subtitle } = this.props;

    return (
      <>
        <div className="d-flex justify-content-center mt-4">
          <h1 className="text-center">{title}</h1>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <h2 className="text-center mt-1 text-danger">
            {subtitle || schools}
          </h2>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <img
            src={CoverLogo}
            className="img-fluid"
            style={{ maxHeight: 300 }}
          />
        </div>
        <div className="my-5 py-5"></div>
      </>
    );
  }
}
