import React, { useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import NavItem from 'react-bootstrap/NavItem';
import NavLink from 'react-bootstrap/NavLink';
import Indicator from './indicator/index.jsx';
import Methods from './methods/index.jsx';
import Introduction from './introduction/index.jsx';
import AddCapacityLink from './capacity/capacity-link.jsx';
import Capacity from './capacity/index.jsx';
import TitlePage from './title_page/index.jsx';
import { get } from '../../../../utils/ajax';
import { alert } from '../../../../utils/alert';
import OrderCapacities from './order_capacities/index.jsx';
import orderBy from 'lodash/orderBy';

function Reports({ reportId, districtReportId, edittable }) {
  const [report, setReport] = useState([]);
  const [activeTab, setActiveTab] = useState('title-page');

  useEffect(() => {
    fetchReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchReport = () => {
    let id = reportId;
    get('/api/web/reports/' + id)
      .then((res) => {
        setReport(res);
      })
      .catch(() => {
        alert('There was an error retrieving the report', 'danger');
      });
  };

  const getCapacityName = (capacity_id) => {
    const capacities = report.capacities || {};
    return capacities.find((c) => c.id === capacity_id).name;
  };
  const reportCapacities = report.report_capacities || [];

  return (
    <div>
      <div className="col-sm-12">
        <div className="row my-3">
          <div className="col-sm-12">
            <Nav variant="pills" defaultActiveKey="/home">
              <Nav.Item>
                <Nav.Link
                  active={activeTab === 'title-page'}
                  onClick={() => setActiveTab('title-page')}
                >
                  Title
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  active={activeTab === 'introduction'}
                  onClick={() => setActiveTab('introduction')}
                >
                  Introduction
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => setActiveTab('methods')}
                  active={activeTab === 'methods'}
                >
                  Methods
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  onClick={() => setActiveTab('indicator')}
                  active={activeTab === 'indicator'}
                >
                  Indicator
                </Nav.Link>
              </Nav.Item>

              <Dropdown as={NavItem}>
                <Dropdown.Toggle
                  as={NavLink}
                  active={activeTab.includes('capacity')}
                >
                  Capacities
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {orderBy(reportCapacities, ['number'], ['asc']).map((c) => (
                    <Dropdown.Item
                      key={`tab-${c.id}`}
                      active={activeTab === `capacity-${c.id}`}
                      onClick={() => setActiveTab(`capacity-${c.id}`)}
                    >
                      {getCapacityName(c.capacity_id)}
                    </Dropdown.Item>
                  ))}
                  {edittable && (
                    <>
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() => setActiveTab('order-capacities')}
                      >
                        Order Capacities
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setActiveTab('add-capacity')}
                      >
                        Add Capacity
                      </Dropdown.Item>
                    </>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          {activeTab === 'title-page' && (
            <TitlePage
              schools={report.list_schools}
              title={report.title}
              subtitle={report.subtitle}
            />
          )}

          {activeTab === 'introduction' && (
            <Introduction reportId={reportId} edittable={edittable} />
          )}

          {activeTab === 'methods' && (
            <Methods reportId={reportId} edittable={edittable} />
          )}

          {activeTab === 'indicator' && (
            <Indicator
              reportId={reportId}
              edittable={edittable}
              year={report.year}
            />
          )}

          {reportCapacities.map(
            (c) =>
              activeTab === `capacity-${c.id}` && (
                <span key={`tab-${c.id}`}>
                  <Capacity
                    capacityName={getCapacityName(c.capacity_id)}
                    capacityId={c.capacity_id}
                    reportCapacityId={c.id}
                    reportId={reportId}
                    edittable={edittable}
                    fetchReport={fetchReport}
                  />
                </span>
              )
          )}

          {activeTab === 'order-capacities' && (
            <OrderCapacities
              capacities={report.capacities}
              reportCapacities={reportCapacities}
              fetchReport={fetchReport}
            />
          )}
          {activeTab === 'add-capacity' && (
            <AddCapacityLink
              reportId={reportId}
              fetchReport={fetchReport}
              getCapacityName={getCapacityName}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Reports;
