import React from 'react';
import PropTypes from 'prop-types';
import ConstructInfoButton from './info-button.jsx';
import ConstructAction from './action.jsx';
import ItemButton from '../item/button.jsx';
import Item from '../item/index.jsx';
import ItemPicker from './item-picker.jsx';
import { destroy } from '../../../../../utils/ajax';
import { alert } from '../../../../../utils/alert';
import PageHeader from '../../shared/PageHeader.jsx';
import { orderBy } from 'lodash';

class Construct extends React.Component {
  static propTypes = {
    fetchCapacity: PropTypes.func.isRequired,
    construct: PropTypes.object.isRequired,
    itemColor: PropTypes.string,
    reportId: PropTypes.number,
    index: PropTypes.number,
    edittable: PropTypes.bool.isRequired,
  };

  handleDestroy = (reportConstructId) => {
    const { fetchCapacity } = this.props;
    destroy(`/api/web/report_constructs/${reportConstructId}`, {
      authenticity_token: document.querySelector('meta[name=csrf-token]')
        .content,
    })
      .then((res) => {
        fetchCapacity();
        alert('Construct removed', 'success');
      })
      .catch(() => {
        alert(
          'There was an error deleting the construct from this report.',
          'danger'
        );
      });
  };

  render() {
    const { fetchCapacity, construct, reportId, itemColor, index, edittable } =
      this.props;
    const items = construct.items;
    const displayItems = items.filter((i) => i.display);

    return (
      <div className="mt-4">
        <div className={`row mt-4 construct-title-${index}`}>
          <div className="col-12">
            <PageHeader
              title={
                <>
                  {construct.construct.name}
                  <span className="ms-2">
                    <ConstructInfoButton
                      reportConstructId={construct.id}
                      reportId={reportId}
                    />
                  </span>
                  {edittable && (
                    <span className="ms-2">
                      <ItemPicker
                        reportConstructId={construct.id}
                        reportId={reportId}
                        fetchData={fetchCapacity}
                        itemIds={items.map((i) => i.item.id)}
                      />
                    </span>
                  )}
                  {edittable && (
                    <span className="ms-2">
                      <ConstructAction
                        reportConstructId={construct.id}
                        destroy={this.handleDestroy}
                      />
                    </span>
                  )}
                </>
              }
            />
          </div>
          <div className="col-4">
            <div className="mt-1 justify-content-evenly"></div>
          </div>
          <div className="row">
            <div className="col-12">
              <p className="ms-1 me-1">{construct.construct.description}</p>
            </div>
          </div>
        </div>

        <div className="row">
          {!!displayItems &&
            displayItems.length > 0 &&
            orderBy(displayItems, ['number'], ['asc']).map((i) => (
              <div
                className="col-3 lr-border min-height-250"
                key={`item-${i.id}`}
              >
                <Item
                  itemId={i.item.id}
                  reportId={reportId}
                  display={i.item.display}
                  updateCapacity={fetchCapacity}
                  itemColor={itemColor}
                  reportItemId={i.id}
                  edittable={edittable}
                />
              </div>
            ))}
          {[...Array(4 - displayItems.length)].map((x, index) => (
            <div
              className="col-3 lr-border min-height-250"
              key={`construct-${construct.id}-empty-item-${index}`}
            >
              <div className="text-center mt5">
                {edittable && (
                  <ItemButton
                    updateCapacity={fetchCapacity}
                    reportConstructId={construct.id}
                    reportId={reportId}
                  />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Construct;
