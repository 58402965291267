import React, { useState } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import OrderConstructItem from './order-construct-item.jsx';
import { orderBy } from 'lodash';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function OrderConstructs({ reportConstructs, fetchReport }) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <Button variant="light" className="btn-sm me-2" onClick={handleShow}>
        <i className="fa-solid fa-arrow-down-1-9 text-danger"></i> Order
        Constructs
      </Button>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Order Constructs</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            {orderBy(reportConstructs, ['number'], ['asc']).map((r, index) => (
              <ListGroup.Item key={`${r.id}-${index}`}>
                <OrderConstructItem
                  reportConstruct={r}
                  fetchReport={fetchReport}
                />
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default OrderConstructs;
