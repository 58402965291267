import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { put } from '../../../../../utils/ajax';


function UpdateCapacityOrder({ reportCapacity, fetchReport, capacities }) {
  const [number, setNumber] = useState(reportCapacity.number);

  const getCapacityName = (capacity_id) => {
    return capacities.find((c) => c.id === capacity_id).name;
  };

  const updateCapacity = () => {
    put(`/api/web/report_capacities/${reportCapacity.id}`, {
      report_capacity: {
        number,
      },
      authenticity_token: document.querySelector('meta[name=csrf-token]')
        .content,
    })
      .then(() => {
        fetchReport();
      })
      .catch(() => {
        alert('There was an error updating the capacity', 'danger');
      });
  };

  return (
    <Row>
      <Col sm={2}>
        <Form.Group>
          <Form.Control
            type="number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />
        </Form.Group>
      </Col>
      <Col>{getCapacityName(reportCapacity.capacity_id)}</Col>
      <Col sm={2}>
        <Button
          variant="primary"
          size="sm"
          onClick={() => updateCapacity(reportCapacity.id)}
        >
          Update
        </Button>
      </Col>
    </Row>
  );
}

export default UpdateCapacityOrder;
