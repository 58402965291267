import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import ConstructInfoModal from "./info-modal.jsx";
import { get } from "../../../../../utils/ajax";
import { alert } from '../../../../../utils/alert';

export default class ConstructInfoButton extends React.Component {
  static propTypes = {
    reportConstructId: PropTypes.number.isRequired,
    reportId: PropTypes.number.isRequired
  };

  state = {
    modalOpen: false,
    reportConstruct: {},
    items: [],
    loading: false
  };

  handleModalHide = () => {
    this.setState({ modalOpen: false });
  };

  openModal = () => {
    const id = this.props.reportConstructId;
    this.setState({ modalOpen: true, loading: true });
    get("/api/web/report_constructs/" + id)
      .then(res => {
        this.setState({ reportConstruct: res });
        this.fetchItems();
      })
      .catch(() => {
        alert(
          "There was an error retrieving the construct information.",
          "danger"
        );
      });
  };

  fetchItems = () => {
    const { reportConstructId, reportId } = this.props;
    get(`/api/web/district_report_items`, {
      params: {
        report_construct_id: reportConstructId,
        report_id: reportId
      }
    })
      .then(res => {
        this.setState({ items: res, loading: false });
      })
      .catch(() => {
        alert("There was an error retrieving the constructs.", "danger");
      });
  };

  render() {
    const { reportId } = this.props
    const { reportConstruct, modalOpen, items, loading } = this.state;
    const { reportConstructId } = this.props;

    return (
      <>
        <ConstructInfoModal
          show={modalOpen}
          reportConstruct={reportConstruct}
          reportConstructId={reportConstructId}
          reportId={reportId}
          onHide={this.handleModalHide}
          items={items}
          loading={loading}
        />

        <Button onClick={this.openModal} className="btn btn-light btn-sm">
          <i className="fa fa-question-circle text-info" />
          &nbsp; More Info
        </Button>
      </>
    );
  }
}
